import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import SEO from "../components/seo"

import { Image, Text } from "../components/Core"
import { Heading } from "../components/Hero"
import TopSection from "./about/TopSection"
import OurMission from "./about/OurMission"
import MeetOurDoctors from "./about/MeetOurDoctors"
import OurReviews from "./about/OurReviews"
import MeetOurTeam from "./about/MeetOurTeam"
import ReasonsWhy from "./about/ReasonsWhy"
import BannerSection from "./about/BannerSection"
import { Columns, Section } from "../components/Containers"
import { CallToAction } from "../components/CTA"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button, ButtonGroup } from "../components/Button"

const AboutUsPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const team = data.team.nodes[0].meetOurTeam.staffImages
  return (
    <Layout language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className="joshua-tree-content about-us">
        {/* <Heading heading={post.heading} blurb={post.topBlurb} /> */}
        <div className="about-us__hero">
          <Columns sideColumnsSize={3}>
            <div className="column is-half-desktop">
              <Text className="px-40--mobile" as="h1" text={post.heading} />
              <Text
                useStringReplace
                as="p"
                className="mb-0 mt-2--mobile px-40--mobile"
                text={post.topBlurb}
              />

              {/* <TheaterVideo
                buttonClass="contained mx-auto--mobile"
                videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
                controls
                playing
                language={language}
              /> */}
            </div>
          </Columns>
        </div>

        <TopSection
          youtube={post.mainVideo.youtube}
          desktopImage={post.mainVideo.imageId}
          mobileImage={post.mainVideo.imageIdMobile}
          language={language}
        />
        <OurMission post={post} language={language} sideColumnIs={4} />
        <MeetOurDoctors post={post} language={language} colorBack />
        <OurReviews post={post} language={language} />
        <ReasonsWhy
          // colorBack
          heading={post.reasonsWhySection.heading}
          hasThisSection={post.reasonsWhySection.hasThisSection}
          list={post.reasonsWhySection.reasonsWhyColumn}
          language={language}
          hasBorderedCards
        />

        <MeetOurTeam
          colorBack={language === "en"}
          post={post}
          team={team}
          language={language}
        />

        <Section className="px-40--mobile">
          <Columns sideColumnsSize={4}>
            <div className="column">
              <Text
                as="h2"
                text={"Financial & Insurance Information"}
                className="has-text-centered-tablet"
              />
              <Text
                className="mt-2"
                text={`We aim to deliver the highest quality oral surgery care at a reasonable cost to our patients. We accept cash, checks, all major credit cards, and CareCredit<sup>®</sup>. If you have questions regarding our financial policies or your existing account, please <a href="/contact-us">contact</a> our knowledgeable staff.`}
              />
              <Text
                className="mt-1"
                text={`As always, we are also here to help you <a href="/contact-us">request your consultation</a> with Dr. Weber or to answer any questions you may have about how we can help you regain your oral health and smile. Reach out to us at any time.`}
              />
              <Image
                useAR={false}
                publicId={"RROS/DEV/CareCredit-logo.avif"}
                className="about-us__logo"
              />
              <Text
                className="mt-1"
                text={`CareCredit is a health and wellness credit card with flexible financing options so you can pay over time for care you want or need. See below to see if your insurance company is in-network:`}
              />
              <div className="about-us__insurance-list">
                <Text as="h6" text="Guardian" />
                <Text as="h6" text="Cigna PPO" />
                <Text as="h6" text="Delta" />
                <Text as="h6" text="Dental Health Alliance (DHA)" />
                <Text as="h6" text="Humana" />
              </div>
              <ButtonGroup isCentered>
                <Button
                  key={
                    "https://www.carecredit.com/apply/?sitecode=bb3lopc06182018"
                  }
                  contained
                  external
                  buttonText={"Apply Now"}
                  href={
                    "https://www.carecredit.com/apply/?sitecode=bb3lopc06182018"
                  }
                  className="is-centered"
                />
              </ButtonGroup>
            </div>
          </Columns>
        </Section>
        {post.bannerSection && post.bannerSection.hasThisSection && (
          <BannerSection
            text={post.bannerSection.text}
            image={post.bannerSection.logoId}
            // colorBack
          />
        )}
        <CallToAction
          colorBack
          heading={post.callToAction.heading}
          blurb={post.callToAction.blurb}
          buttons={post.callToAction.buttons}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            blurb
            heading
            number
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          useImageStrip
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
          imageStrip {
            youtube
            images {
              imageId
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          text
        }
        metaTitle
        metaDescription
        title
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        bannerSection {
          hasThisSection
          logoId
          text
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
    team: allUniquePagesJson(
      filter: { template: { eq: "about-us" }, language: { eq: "en" } }
    ) {
      nodes {
        meetOurTeam {
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
              spanishTitle
            }
          }
        }
      }
    }
  }
`

export default AboutUsPage
